<style lang="less" scoped>
.home{
  padding: 12px 0 0 0;
}
.advertising-box{
  height: 82px;
  margin: 16px 0;
  border-radius: 6px;
  overflow: hidden;
}
.advertising-box img{
  display: block;
  height: 100%;
  width: 100%;
}
.cont-box{
  padding-top: 10px;
  min-height: 434px;
}
.everything-box{
  margin-top: 10px;
  height: 458px;
  padding-left: 254px;
  position: relative;
  .box-left{
    width: 234px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(135deg, #165CFF 0%, #6795FF 100%);
    border-radius: 6px;
    padding-top: 55px;
    .everything-nav-box{
      position: relative;
      .nav-item-box{
        position: absolute;
        top: 0;
        left: 0;
        .left-item{
          width: 180px;
          margin-bottom: 10px;
          line-height: 34px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          transition: all .3s;
        }
        .left-item-on{
          font-weight: bold;
          color: #5286FF;
        }
      }
      .animation-box{
        width: 180px;
        background-color: #ffffff;
        height: 34px;
        border-radius: 0 20px 20px 0;
        transition: all .3s;
      }
    }
  }
  .box-right{
    position: relative;
    .list-enter, .list-leave-to {
      opacity: 0;
    }
    .list-enter-active, .list-leave-active {
      transition: all .2s;
    }
    .right-top{
      background-color: #ffffff;
      padding: 24px;
      border-radius: 6px;
      position: relative;
    }
    .right-bot{
      margin-top: 10px;
      background-color: #ffffff;
      padding: 13px;
      border-radius: 6px;
      font-size: 16px;
      text-align: center;
    }
    // .news-li-border{
    //   border-bottom: 1px dashed  rgba(233, 233, 233, 1)
    // }
  }
}
.news-one{
  position: relative;
  padding: 15px 370px 15px 24px;
  background: linear-gradient(180deg, rgba(86, 137, 255, .1) 0%, #FFFFFF 100%);
  .news-one-image{
    height: 156px;
    width: 327px;
    border-radius: 3px;
    position: absolute;
    top: 15px;
    right: 24px;
  }

}
  .news-one-cont{
    height: 156px;
    position: relative;
    .news-one-cont-title{
      font-size: 16px;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      padding-left: 15px;
      padding: 5px 0 10px 12px;
      border-bottom: 1px solid #E9E9E9;
      position: relative;
    }
    .news-one-cont-title-a{
      font-size: 16px;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      // padding-left: 15px;
      padding: 5px 0 10px 8px;
      border-bottom: 1px solid #E9E9E9;
      position: relative;
    }
    .news-one-cont-title-a:hover{
      color: #155BDD;
    }
    .news-one-cont-title::before{
      content: '';
      height: 18px;
      width: 3px;
      background-color: #FF9B3C;
      position: absolute;
      top: 50%;
      margin-top: -11px;
      left: 0;
    }
    .news-one-cont-title:hover{
      color: #155BDD;
    }
    .news-one-cont-text-a{
      margin: 15px;
      font-size: 13px;
      color: rgba(153, 153, 153, 1);
      height: 50px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .news-one-cont-text{
      margin: 15px 50px 15px 10px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
      height: 72px;
      line-height: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
    .news-one-cont-time{
      position: absolute;
      left: 10px;
      bottom: 5px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
    }
  }
.news-li-border{
  border-bottom: 1px dashed  rgba(233, 233, 233, 1)
}
.news-li{
  position: relative;
  padding: 12px 0px 12px 22px;
  line-height: 16px;
}
.news-li::before{
  content: '';
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #D8D8D8;
  position: absolute;
  top: 50%;
  left: 7px;
  margin-top: -3px;
}
.news-left{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.news-left:hover{
  color: #155BDD;
}
.news-time{
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 10px;
}
.international-box{
  background-color: #ffffff;
  border-radius: 6px;
  position: relative;
}
.international-title{
  height: 58px;
  line-height: 58px;
  padding: 0 24px;
  position: relative;
}
.international-title::after{
  content: '';
  height: 1px;
  width: 100%;
  background-color: #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.international-title-left{
  font-size: 16px;
  height: 100%;
  position: relative;
  float: left;
  margin-right: 40px;
  cursor: pointer;
}
.international-title-left-on{
  color: #FF761A;
  font-weight: bold;
}
.international-title-left-on::after{
  content: '';
  height: 3px;
  width: 100%;
  background-color: #FF761A;
  position: absolute;
  bottom: 0;
  left: 0;
}
.international-title-right{
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
.international-title-right:hover{
  color: #155BDD;
}
.international-title-right-arrows{
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid #FF761A;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
}
.international-title-right-arrows::after{
  content: '';
  height: 5px;
  width: 5px;
  border-top: 1px solid #FF761A;
  border-right: 1px solid #FF761A;
  position: absolute;
  bottom: 4px;
  left: 3px;
  transform: rotate(45deg);
}
.international-cont{
  padding: 13px 24px;
}
.international-right{
  border-radius: 6px;
  background-color: #ffffff;
  overflow: hidden;
}
.international-right-top{
  height: 166px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat:no-repeat;
  padding: 24px 15px 0 24px;
}
.international-right-ul{
  padding: 0 24px 12px 24px;
}
.international-right-title{
  font-size: 18px;
  padding-left: 10px;
  line-height: 18px;
  height: 18px;
  margin-bottom: 30px;
  position: relative;
}
.international-right-title::before{
  content: '';
  height: 18px;
  width: 4px;
  border-radius: 2px;
  background-color: #FF9B3C;
  position: absolute;
  top: 0;
  left: 0;
}
.international-right-li{
  position: relative;
  padding-bottom: 5px;
  padding-left: 30px;
  min-height: 20px;
  width: 245px;
}
.international-right-li::before{
  content: '答';
  height: 20px;
  width: 20px;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  border-radius: 8px 8px 8px 0;
  background-color: #4E84FF;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.8);
}
.international-right-lia{
  position: relative;
  padding-bottom: 5px;
  padding-left: 30px;
  min-height: 20px;
}
.international-right-lia::before{
  content: '问';
  height: 20px;
  width: 20px;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  border-radius: 8px 8px 8px 0;
  background-color: #FF9852;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.8);
}
</style>

<template>
  <div class="home">
    <!-- <video width="1200" controlslist="nodownload"
      src="http://39.98.59.164:6790/api/system/static/1537277/169/15/66c58c74da9143ef9f5f83a202fe1590.mp4"
      controls="controls"></video> -->
    <div class="cont-box clearfix">
      <div v-if="newsArr" class="pull-left" style="width: 825px;">
        <Title :title="newsArr.name"></Title>
        <div class="cont-box">
          <div class="international-box" style="padding: 15px;">
            <div style="position: relative;padding: 0 0px 15px 400px;min-height: 272px;">
              <Swiper
                style="position: absolute;top: 0;left: 0;"
                :swiperConfig="swiperConfig"
                :list="swiperArr"
                :current="swiperCurrent"
                @SwiperFun="SwiperFun"
              ></Swiper>
              <div v-for="(item, index) of newsArr.arrA" :key="index" class="news-one-cont" :class="{'news-li-border':index > 0}" style="height: 134px;padding-top: 10px;">
                <div class="news-one-cont-title-a" @click="itemDetail(item.id, newsArr.id, newsArr.id)">{{item.title}}</div>
                <div class="news-one-cont-text-a">{{item.contentPure}}</div>
              </div>
            </div>
            <ul>
              <li class="news-li-border" v-for="(item, index) of newsArr.arrB" :key="index">
                <div class="news-li">
                  <div class="news-left" style="width: 550px;" @click="itemDetail(item.id, newsArr.id, newsArr.id)">{{item.title}}</div>
                  <div class="news-time">{{item.publishTime}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="noticeArr" class="pull-right" style="width: 367px;">
        <Title :title="noticeArr.name"></Title>
        <div class="cont-box">
          <div class="international-right">
              <ul class="international-right-ul" style="padding-top: 2px;">
                <li v-for="(item, index) of noticeArr.arr" :key="index" class="news-li-border">
                  <div class="news-li">
                    <div class="news-left" style="width: 150px;" @click="toDetail(noticeArr.id, item.id)">{{item.title}}</div>
                    <div class="news-time">{{item.publishTime}}</div>
                  </div>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="advertisingList[0]" class="advertising-box">
      <img :src="advertisingList[0].image" alt="" @click="advertisingClick(advertisingList[0].url)">
    </div>
    <div class="cont-box clearfix">
      <div v-if="policyArr" class="pull-left" style="width: 825px;">
        <Title :title="policyArr.name"></Title>
        <div class="cont-box">
          <div class="international-box" v-loading="policyLoading">
            <div class="international-title">
              <div class="international-title-left" :class="{'international-title-left-on':index == policyArr.index}"
                v-for="(item, index) of policyArr.childrenName" :key="index"
                @click="policyItemClick(index)">{{item}}</div>
              <div class="pull-right international-title-right" @click="more(policyArr.id, policyArr.children[policyArr.index])">
                <span>更多动态</span>
                <div class="international-title-right-arrows"></div>
              </div>
            </div>
            <div v-for="(val, ind) of policyArr.childrenName" :key="ind">
              <div v-if="ind == policyArr.index" class="international-cont">
                <ul>
                  <li :class="{'news-li-border':index > 0}" v-for="(item, index) of policyArr[ind]" :key="index">
                    <div v-if="index == 0" class="news-one" style="padding: 5px 0 15px 347px;background: none;">
                      <div class="news-one-image" style="top: 5px;left: 0;">
                        <img :src="item.image" alt="" style="display: block;height: 100%;width: 100%;">
                      </div>
                      <div class="news-one-cont">
                        <div class="news-one-cont-title" @click="itemDetail(item.id, policyArr.children[ind].id, policyArr.id)">{{item.title}}</div>
                        <div class="news-one-cont-text">{{item.contentPure}}</div>
                        <div class="news-one-cont-time">{{item.publishTime}}</div>
                      </div>
                    </div>
                    <div v-else class="news-li">
                      <div class="news-left" style="width: 550px;" @click="itemDetail(item.id, policyArr.children[ind].id, policyArr.id)">{{item.title}}</div>
                      <div class="news-time">{{item.publishTime}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pull-right" style="width: 367px;">
        <Title :title="standardArr.name"></Title>
        <div class="cont-box">
          <div class="international-right">
            <!-- <div class="international-right-top" :style="`background-image: url(${bannerImage3});`">
              <div class="international-right-title">专业知识</div>
              <div class="international-right-lia">什么是国际标准化？</div>
              <div class="international-right-li">指在国际范围内有众多的国家或组织共同参与开展的标准化活动</div>
            </div> -->
            <ul class="international-right-ul">
              <li v-for="(item, index) of standardArr.arr" :key="index" class="news-li-border">
                <div class="news-li">
                  <div class="news-left" style="width: 150px;" @click="toDetail(standardArr.id, item.id)">{{item.title}}</div>
                  <div class="news-time">{{item.publishTime}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="advertisingList[1]" class="advertising-box">
      <img :src="advertisingList[1].image" alt="" @click="advertisingClick(advertisingList[1].url)">
    </div>
    <Member></Member>
    <div v-if="advertisingList[2]" class="advertising-box" style="margin-bottom: 24px;">
      <img :src="advertisingList[2].image" alt="" @click="advertisingClick(advertisingList[2].url)">
    </div>
  </div>
</template>

<script>
import { getNavAll, getList, advertisingPage, slideshowPage } from '@/api/web'

import Title from '@/components/title/Title'
import Member from '@/components/member/Member'
import Swiper from '@/components/swiper/Swiper.vue'

export default {
  components: {
    Title,
    Member,
    Swiper
  },
  name: 'Home',
  data () {
    return {
      bannerImage3: require('../../../assets/images/banner_c.png'),
      loading: false,
      advertisingList: [], // 广告位
      newsArr: '', // 时政要闻
      newsArrA: [], // 时政要闻
      newsArrB: [], // 时政要闻
      newsLoading: false,
      noticeArr: '', // 通知公告
      policyArr: '', // 政策法规
      policyLoading: false,
      standardArr: '', // 国际标准化
      // 轮播图
      imageList: '',
      swiperArr: [],
      swiperConfig: {
        type: 'image',
        autoplay: true, // 自动播放 默认false
        interval: 4000, // 自动播放的间隔 默认3000
        circular: true, // 首位衔接 默认false
        animationTime: 300,
        indicator: false, // 指示点 默认false
        arrows: true, // 左右箭头 默认false
        height: 265,
        width: 380
      },
      swiperCurrent: 0
    }
  },
  created () {
    this.initialData()
    this.getadvertisingPage()
    this.getSlideshow()
  },
  mounted () {},
  methods: {
    advertisingClick (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    more (id, item) {
      let url = item.url
      if (!item.url) {
        url = '/list/normal'
      }
      this.$router.push({ path: url, query: { id: id, cid: item.id } })
    },
    itemDetail (aid, cid, id) {
      const { href } = this.$router.resolve({
        path: '/detail',
        query: { id: id, cid: cid, articleId: aid }
      })
      window.open(href, '_blank')
    },
    toDetail (id, articleId) {
      const { href } = this.$router.resolve({
        path: '/detail',
        query: { id: id, articleId: articleId }
      })
      window.open(href, '_blank')
    },
    // 事实要闻 点击
    newsItemClick (index) {
      if (this.newsArr.index === index) {
        return
      }
      if (this.newsArr[index]) {
        this.newsArr.index = index
      } else {
        this.newsLoading = true
        this.requestData(this.newsArr.children[index].id, 5).then(res => {
          this.newsLoading = false
          this.newsArr[index] = res
          this.newsArr.index = index
        })
      }
    },
    // 政策法规点击
    policyItemClick (index) {
      if (this.policyArr.index === index) {
        return
      }
      if (this.policyArr[index]) {
        this.policyArr.index = index
      } else {
        this.policyLoading = true
        this.requestData(this.policyArr.children[index].id, 5).then(res => {
          this.policyLoading = false
          this.policyArr[index] = res
          this.policyArr.index = index
        })
      }
    },
    initialData () {
      getNavAll({}).then(res => {
        if (res.code === '000') {
          const list = res.result
          list.forEach((element, index) => {
            if (element.code === 'ssyw') {
              const list = this.getggAll({ name: element.name, id: element.id, arrA: [], arrB: [], flag: 1 }, 5)
              this.newsArr = list
            }
            if (element.code === 'tzgg') {
              this.noticeArr = this.getggAll({ name: element.name, id: element.id, arr: '' }, 10)
            }
            if (element.code === 'zcfg') {
              const policyArrData = this.getggAll({ name: element.name, id: element.id, index: 0, children: element.children }, 5)
              this.setPolicyArr(policyArrData)
            }
            if (element.code === 'gjbzh') {
              this.standardArr = this.getggAll({ name: element.name, id: element.children[0].id, arr: '' }, 10)
            }
          })
        }
      })
    },
    setPolicyArr (data) {
      const that = this
      setTimeout(() => {
        if (data[0]) {
          that.policyArr = data
        } else {
          that.setPolicyArr(data)
        }
      }, 300)
    },
    getggAll (arr, size) {
      if (arr.children) {
        arr.childrenName = []
        arr.children.forEach((element, index) => {
          arr.childrenName.push(element.name)
        })
        this.requestData(arr.children[0].id, size).then(res => {
          arr[0] = res
        })
      } else {
        this.requestData(arr.id, size).then(res => {
          if (arr.flag === 1) {
            res.forEach((val, ind) => {
              if (ind < 2) {
                arr.arrA.push(val)
              } else {
                arr.arrB.push(val)
              }
            })
          } else {
            arr.arr = res
          }
        })
      }
      return arr
    },
    requestData (id, size) {
      return new Promise(function (resolve, reject) {
        const data = {
          cmsType: id,
          pageSize: size,
          pageNo: 1
        }
        getList(data).then(res => {
          if (res.code === '000') {
            const data = res.result.records
            data.forEach((val, ind) => {
              if (val.image) {
                val.image = process.env.VUE_APP_IMG_URL + val.image
              }
            })
            resolve(data)
          }
        })
      })
    },
    // 获取广告位
    getadvertisingPage () {
      advertisingPage({}).then(res => {
        if (res.code === '000') {
          const data = res.result
          data.forEach((val, ind) => {
            val.image = process.env.VUE_APP_IMG_URL + val.image
          })
          this.advertisingList = data
        }
      })
    },
    // 获取轮播图
    getSlideshow () {
      slideshowPage({}).then(res => {
        if (res.code === '000') {
          const list = res.result
          const arr = []
          list.forEach(element => {
            element.image = process.env.VUE_APP_IMG_URL + element.image
            arr.push({
              image: element.image,
              title: element.title,
              url: element.url
            })
          })
          this.imageList = list
          this.swiperArr = arr
        }
      })
    },
    SwiperFun (e) {
      const url = this.imageList[e].url
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>
